import * as React from 'react'

import Layout from '../../components/layout'

import * as styles from './index.module.scss'

const NotFoundPage = () => {
  return (
    <Layout
      pageTitle="404"
      className={styles.content}
    >
      <article className={styles.alert}>
        Page non trouvée
      </article>
    </Layout>
  )
}

export default NotFoundPage